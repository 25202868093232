import React, { Component } from 'react';
import { connect } from 'react-redux';
import AlertSuccessStudy from '../vocabulary/AlertSuccessStudy';
import AlertErrorStudy from '../vocabulary/AlertErrorStudy';
import { Link } from 'react-router-dom';
import TinySegmenter from 'tiny-segmenter';

class ViewAndChooseMean extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectOption: '',
            optionValue: this.takeOptions(),
            showAlertStudy: ''
        }
    }

    clickIconSound = () => {
        var clickIcon = document.querySelector('.fa-solid.fa-volume-high');
        // console.log(clickIcon);
        var soundCard = document.querySelector('.audios');
        clickIcon.onclick = function () {
            soundCard.play();
            this.classList.toggle('scale');
            this.addEventListener("webkitAnimationEnd", function () {
                this.classList.remove('scale')
            })
        }
    }
    takeOptions = () => {
        if (this.props.study.vocabularyData !== null) {
            const { vocabularyData } = this.props.study;
            const correctOptions = this.props.vocab_id;
            const otherOptions = vocabularyData.map(value => value.vocab_id).filter(vocab_id => vocab_id !== correctOptions);
            const randomOptions = this.shuffle(otherOptions).slice(0, 3);
            const allOptions = this.shuffle([...randomOptions, correctOptions]);
            return allOptions;
        }
    }
    shuffle = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    clickOptionChoose = (option) => {
        var optionChoose = document.querySelectorAll('.optionChoose')
        for (var i = 0; i < optionChoose.length; i++) {
            optionChoose[i].onclick = function () {
                for (let a = 0; a < optionChoose.length; a++) {
                    optionChoose[a].classList.remove('optionChooseColor')

                }
                this.classList.add('optionChooseColor')
            }
        }
        this.setState({
            selectOption: option
        });
    }

    clickCheck = () => {
        const correctOptions = this.props.vocab_id;
        const { selectOption } = this.state;
        if (correctOptions === selectOption) {
            this.setState({
                showAlertStudy: 'success'
            });
        } else {
            this.setState({
                showAlertStudy: 'error',
            });
        }
    }
    render() {
        const { vocabularyData } = this.props.study;
        const segmenter = new TinySegmenter();
        const exampleVocab = this.props.example;
        const exampleVocabArray = segmenter.segment(exampleVocab);

        const startIndex = this.props.example.indexOf(this.props.name);
        const endIndex = startIndex + this.props.name.length;
        console.log("startIndex" + startIndex);
        console.log("endIndex" + endIndex);
        let exampleVocabArrayHighlighted = [];
        let currentIndex = 0;
        exampleVocabArray.forEach((word, index) => {
            const wordStart = currentIndex;
            console.log("word.length" + word.length);
            const wordEnd = wordStart + word.length;
            currentIndex = wordEnd;
            console.log("wordStart" + wordStart);
            console.log("wordEnd" + wordEnd);
            if (wordEnd > startIndex && wordStart < endIndex) {
                // Nếu từ nằm trong phạm vi cần gạch chân
                exampleVocabArrayHighlighted.push(
                    <span
                        key={index}
                        style={{
                            color: "#ff0000e0",
                            fontWeight: "400",
                        }}
                    >
                        {word}
                    </span>
                );
            } else {
                // Các từ không cần gạch chân
                exampleVocabArrayHighlighted.push(<span key={index}>{word}</span>);
            }
        });
        const meanOptionById = (id) => {
            const meanOption = vocabularyData.find(value => value.vocab_id === id);
            return meanOption ? meanOption.mean : '';
        }
        return (
            <div className="listenAndChoose ViewAndChooseMean">
                <h2>Hãy xem ví dụ và chọn đáp án </h2>
                <div className="viewVocabExample">
                    {exampleVocabArrayHighlighted}
                </div>
                <div className="optionChooseALL">
                    {Array.isArray(this.state.optionValue) ?
                        (this.state.optionValue.map(value => (
                            <button key={value} type="button" className="btn btn-primary optionChoose" onClick={() => this.clickOptionChoose(value)}>
                                {meanOptionById(value)}
                            </button>
                        )))
                        : (
                            <Link to='/homeMember'>Vui lòng chọn vào đây để tiến hành ôn tập lại</Link>
                        )
                    }
                </div>
                <button name="" id="" className="btn btn-primary check" onClick={this.clickCheck}>
                    kiểm tra
                </button>
                {this.state.showAlertStudy === 'success' && <AlertSuccessStudy name={this.props.name} pronunciation={this.props.pronunciation} mean={this.props.mean} example={this.props.example} example_mean={this.props.example_mean} sound = {this.props.sound} howtolearnNext={this.props.howToLearnNext} />}
                {this.state.showAlertStudy === 'error' && <AlertErrorStudy name={this.props.name} pronunciation={this.props.pronunciation} mean={this.props.mean} example={this.props.example} example_mean={this.props.example_mean} sound = {this.props.sound} howToLearnBack={this.props.howToLearnBackWrite} nameButton={'Quay lại'} />}
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        study: state.study
    }
}
export default connect(mapStateToProps)(ViewAndChooseMean);