import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChatBot from 'react-simple-chatbot';
import { saveCommunicationShow } from '../react-redux/actions/studyAction';
import { Link } from 'react-router-dom';
import AudioRecorder from './AudioRecorder';

class ChatBotsMember extends Component {
    completeLesson = () => {
        this.props.saveCommunicationShow(this.props.communication_id, this.props.user_id);
    }
    render() {
        return (
            <ChatBot
                headerTitle="Luyện shadowing nào"
                placeholder='Chọn vào mic để luyện tập =========>'
                recognitionEnable={true}
                recognitionLang="ja"
                recognitionPlaceholder='Nói câu trả lời'
                botDelay='2000'
                steps={[
                    {
                        id: 'sound',
                        component: (
                            <audio className='audioShadowing' controls>
                                <source src={this.props.sound_shadowing} type="audio/mp3" />
                            </audio>
                        ),
                        trigger: '1',
                    },
                    {
                        id: '1',
                        component: (
                            <div className='shadowing_five'>
                                
                                {this.props.one_a && <p>A: {this.props.one_a}</p>}
                                {this.props.one_b && <p>B: {this.props.one_b}</p>}
                                {this.props.two_a && <p>A: {this.props.two_a}</p>}
                                {this.props.two_b && <p>B: {this.props.two_b}</p>}
                                {this.props.three_a && <p>A: {this.props.three_a}</p>}
                                {this.props.three_b && <p>B: {this.props.three_b}</p>}
                                {this.props.four_a && <p>A: {this.props.four_a}</p>}
                                {this.props.four_b && <p>B: {this.props.four_b}</p>}
                                {this.props.five_a && <p>A: {this.props.five_a}</p>}
                                {this.props.five_b && <p>B: {this.props.five_b}</p>}
                                
                            </div>
                        ),
                        trigger: '2',
                    },
                    {
                        id: '2',
                        component: (
                            <h5>B1: Hãy vừa nghe vừa theo dõi đoạn hội thoại và nhẩm theo mà không phát ra âm thanh</h5>
                        ),
                        trigger: '3',
                    },
                    {
                        id: '3',
                        component: (
                            <h5>B2: Vui lòng chọn</h5>
                        ),
                        trigger: '4',
                    },
                    {
                        id: '4',
                        options: [
                          { value: 1, label: 'Sẵn sàng luyện tập', trigger: '6' },
                          { value: 2, label: 'Không sẵn sàng', trigger: '5' },
                        ],
                    },
                    {
                        id: '5',
                        message: 'Vui lòng chọn lại',
                        trigger: '4',
                    },
                    {
                        id: '6',
                        message: 'Luyện tập thôi',
                        trigger: '7',
                    },
                    {
                        id: '7',
                        message: 'Vui lòng lặp lại vai A :  => ' + this.props.one_a,
                        trigger: '8',
                    },
                    {
                        id: '8',
                        user: true,
                        trigger: '9',
                    },
                    {
                        id: '9',
                        message: 'Vui lòng lặp lại vai B: => ' + this.props.one_b,
                        trigger: '10',
                    },
                    {
                        id: '10',
                        user: true,
                        trigger: '11',
                    },
                    {
                        id: '11',
                        message: 'Vui lòng lặp lại vai A :  => ' + this.props.two_a,
                        trigger: '12',
                    },
                    {
                        id: '12',
                        user: true,
                        trigger: this.props.two_b ? '13' : '27',
                    },
                    {
                        id: '13',
                        message: 'Vui lòng lặp lại vai B: => ' + this.props.two_b,
                        trigger: '14',
                    },
                    {
                        id: '14',
                        user: true,
                        trigger: this.props.three_a ? '15' : '27',
                    },
                    {
                        id: '15',
                        message: 'Vui lòng lặp lại vai A :  => ' + this.props.three_a,
                        trigger: '16',
                    },
                    {
                        id: '16',
                        user: true,
                        trigger: this.props.three_b ? '17' : '27',
                    },
                    {
                        id: '17',
                        message: 'Vui lòng lặp lại vai B: => ' + this.props.three_b,
                        trigger: '18',
                    },
                    {
                        id: '18',
                        user: true,
                        trigger: this.props.four_a ? '19' : '27',
                    },
                    {
                        id: '19',
                        message: 'Vui lòng lặp lại vai A :  => ' + this.props.four_a,
                        trigger: '20',
                    },
                    {
                        id: '20',
                        user: true,
                        trigger: this.props.four_b ? '21' : '27',
                    },
                    {
                        id: '21',
                        message: 'Vui lòng lặp lại vai B: => ' + this.props.four_b,
                        trigger: '22',
                    },
                    {
                        id: '22',
                        user: true,
                        trigger: this.props.five_a ? '23' : '27',
                    },
                    {
                        id: '23',
                        message: 'Vui lòng lặp lại vai A :  => ' + this.props.five_a,
                        trigger: '24',
                    },
                    {
                        id: '24',
                        user: true,
                        trigger: this.props.five_b ? '25' : '27',
                    },
                    {
                        id: '25',
                        message: 'Vui lòng lặp lại vai B: => ' + this.props.five_b,
                        trigger: '26',
                    },
                    {
                        id: '26',
                        user: true,
                        trigger: this.props.five_a ? '27' : '27',
                    },
                    {
                        id: '27',
                        component: (
                            <div>Nghĩa: {this.props.mean_shadowing}</div>
                        ),
                        trigger: '28'
                    },
                    {
                        id: '28',
                        component: (
                            <div>B3: Luyện tập theo ý nghĩa mà không cần nhìn lời thoại kết hợp với ghi âm
                                <AudioRecorder/>
                            </div>
                        ),
                        trigger: '29'
                    },
                    {
                        id: '29',
                        component: (
                            <Link onClick={() => this.completeLesson()} className='linkCheckComplete'><i className="fa-solid fa-square-check"></i>Đánh dấu bài học hoàn thành</Link>
                        ),
                        end: true,
                    },
                ]}
            />
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        study: state.study
    }
}
const mapDispatchToProps = {
    saveCommunicationShow
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatBotsMember);